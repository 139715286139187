import { Viewer } from "../utils/webglViewer.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Flip } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, Flip);

export default (linksList) => {
    const webGlFile = "/dist/3d/model/IPZ_webgl_13.glb";
    let minWebglViewer;
    return {
        isModelLoaded: false,
        showWebGL: false,
        videosPlayer: [],
        webglOuterState: false,
        isWebGlSticked: false,
        isFullScreen: false,
        currentBuilding: null,
        linksArray: null,
        buildingsBase: [
            {
                key: "space-hub",
                name: "Space Hub",
                camera: {
                    x: -374.75689488959677,
                    y: 62.22185657107018,
                    z: 196.4985268197187,
                },

                position: {
                    x: 147.3135745133446,
                    y: -113.78108884700903,
                    z: 120.60919599307101,
                },
            },
            {
                key: "buro-zuri",
                name: "Büro Züri",
                camera: {
                    x: -326.17333535893727,
                    y: 44.876941011987796,
                    z: 144.35697579718163,
                },
                position: {
                    x: 144.51010827719628,
                    y: -107.37300568889752,
                    z: 86.46146144465149,
                },
            },
            {
                key: "hq-ipz",
                name: "HQ IPZ",
                camera: {
                    x: -290.2381364168703,
                    y: 33.77963568648644,
                    z: 121.98469516896276,
                },
                position: {
                    x: 117.58675522262526,
                    y: -105.91167837288143,
                    z: -25.997148395108248,
                },
            },
            {
                key: "startbahn-29",
                name: "Startbahn 29",
                camera: {
                    x: -288.80639905605295,
                    y: 74.34875944580185,
                    z: 107.9403499826641,
                },
                position: {
                    x: 14.797671887597412,
                    y: -239.27382596385618,
                    z: -5.990186782031545,
                },
            },
            {
                key: "angst-pfister",
                name: "Angst & Pfister",
                camera: {
                    x: -190.38852970969054,
                    y: 19.458779069144512,
                    z: 132.07525132789453,
                },
                position: {
                    x: -93.72069165591648,
                    y: -113.41196699491893,
                    z: -167.64731747402902,
                },
            },
            {
                key: "cellsius",
                name: "Cellsius",
                camera: {
                    x: -163.4817857208429,
                    y: 14.82010652686401,
                    z: 114.50703608988181,
                },
                position: {
                    x: -89.71692097882989,
                    y: -134.63529340469447,
                    z: -175.22209973303484,
                },
            },
            {
                key: "aris",
                name: "ARIS",
                camera: {
                    x: -119.83428329778809,
                    y: 14.350182391497086,
                    z: 96.24942261515383,
                },
                position: {
                    x: -248.24235551687016,
                    y: -119.13778774053863,
                    z: -126.86134986965655,
                },
            },
            {
                key: "amz",
                name: "AMZ",
                camera: {
                    x: -76.12892709972871,
                    y: 20.097322974718395,
                    z: 72.82512576516318,
                },
                position: {
                    x: -247.75347598163287,
                    y: -121.6614206137958,
                    z: -125.48043399401742,
                },
            },
            {
                key: "swissloop",
                name: "Swissloop",
                camera: {
                    x: -42.41024756226628,
                    y: 22.269146165860903,
                    z: 42.09059618977136,
                },
                position: {
                    x: -214.03479644417044,
                    y: -119.48959742265329,
                    z: -156.21496356940924,
                },
            },
            {
                key: "center-for-immersive-wave",
                name: "Center for Immersive Wave Experimentation",
                camera: {
                    x: -37.65246772518489,
                    y: 21.520666255764482,
                    z: 26.66983491057394,
                },
                position: {
                    x: -189.8909717846725,
                    y: -115.78122774485483,
                    z: -178.1996331588615,
                },
            },
            {
                key: "eth-hangar",
                name: "ETH Hangar",
                camera: {
                    x: 11.942134229582223,
                    y: 76.71438983133888,
                    z: 82.7713787357801,
                },
                position: {
                    x: -252.75741843942353,
                    y: -150.2561326123223,
                    z: -58.70287124716999,
                },
            },
            {
                key: "event-hangar",
                name: "Event Hangar",
                camera: {
                    x: 19.473101405548228,
                    y: 53.157867280174955,
                    z: 39.48672910106674,
                },
                position: {
                    x: -6.60377564689697,
                    y: -116.76410067622284,
                    z: -218.6359217604764,
                },
            },
        ],
        buildings: [],
        tween: [],

        init() {
            this.linksArray = linksList;

            this.buildings = this.buildingsBase.map((v) => {
                const link = this.linksArray.find((l) => l.key === v.key);
                return {
                    ...v,
                    ...link,
                };
            });

            this.$watch("activeLink", () => {
                this.navigateToBuilding(this.activeLink.building);
            });

            this.$nextTick(() => {
                this.startScrollTrigger();
            });

            this.options = {
                kiosk: false,
                model: "",
                preset: "",
                cameraPosition: [-301, 83, -343],
                objectPosition: [-125, -23, -236],
            };

            this.setWebGlView();
        },

        startScrollTrigger() {
            let mm = gsap.matchMedia();

            mm.add("(min-width: 1024px)", () => {
                this.tween.push(
                    gsap.fromTo(
                        this.$refs.copy,
                        { y: "20%" },
                        {
                            y: "-50%",
                            scrollTrigger: {
                                trigger: this.$refs.copy,
                                start: "top bottom",
                                end: "bottom top",
                                scrub: 0.5,
                                markers: false,
                            },
                        },
                    ),
                );
            });
        },

        navigateToBuilding(buildingName) {
            const object = this.buildings.find((v) => v.key === buildingName);

            this.currentBuilding = object;

            this.playToBuildingAnimation(object);
        },

        goToNextBuilding() {
            const currentBuildingIndex =
                this.buildings.findIndex(
                    (v) => v.key === this.currentBuilding.key,
                ) || 0;
            const nextBuildingIndex =
                currentBuildingIndex + 1 >= this.buildings.length
                    ? 0
                    : currentBuildingIndex + 1;

            const nextBuilding = this.buildings[nextBuildingIndex];

            this.currentBuilding = nextBuilding;
            this.playToBuildingAnimation(nextBuilding);
        },

        goToPrevBuilding() {
            const currentBuildingIndex =
                this.buildings.findIndex(
                    (v) => v.key === this.currentBuilding.key,
                ) || 0;
            const prevBuildingIndex =
                currentBuildingIndex - 1 < 0
                    ? this.buildings.length - 1
                    : currentBuildingIndex - 1;
            const prevBuilding = this.buildings[prevBuildingIndex];

            this.currentBuilding = prevBuilding;
            this.playToBuildingAnimation(prevBuilding);
        },

        showFullScreen() {
            if (!minWebglViewer) return;
            this.isFullScreen = true;
            minWebglViewer.goToFullScreen();
        },

        closeFullWindow() {
            if (!minWebglViewer) return;
            this.isFullScreen = false;
            if (this.currentBuilding.kay !== this.activeLink.building) {
                this.navigateToBuilding(this.activeLink.building);
            }
            minWebglViewer.goToDefaultScreen();
        },

        playToBuildingAnimation(building) {
            if (!building || !minWebglViewer) return;

            const { x, y, z } = building.position;
            const {
                x: cameraX,
                y: cameraY,
                z: cameraZ,
            } = building.camera || building.position;

            const timeline = gsap.timeline();

            if (building.position) {
                timeline.to(minWebglViewer.controls.target, {
                    x,
                    y,
                    z,
                    duration: 2,
                    ease: "power1.inOut",
                });
            }

            if (building.camera) {
                timeline.to(
                    minWebglViewer.defaultCamera.position,
                    {
                        x: cameraX,
                        y: cameraY,
                        z: cameraZ,
                        duration: 2,
                        ease: "power1.inOut",
                    },
                    "<0%",
                );
            }
        },

        setWebGlView() {
            if (minWebglViewer) {
                minWebglViewer.clear();
            }

            minWebglViewer = new Viewer(this.$refs.webgl2, this.options);

            minWebglViewer
                .load(webGlFile)
                .catch((e) => this.onError(e))
                .then(() => {
                    this.isModelLoaded = true;
                });
        },

        onError(error) {
            console.error(error);
        },

        destroy() {},

        get goToPageUrl() {
            if (this.isFullScreen) {
                return this.currentBuilding &&
                    this.currentBuilding?.blockLink?.url
                    ? this.currentBuilding?.blockLink?.url
                    : null;
            }

            return this.activeLink ? this.activeLink.url : null;
        },

        get showUnderline() {
            return !!this.currentBuilding?.blockLink?.url;
        },

        get goToPageTitle() {
            return this.isFullScreen
                ? this.currentBuilding?.blockLink?.text ||
                      this.currentBuilding.name
                : this.activeLink
                  ? this.activeLink.title
                  : null;
        },
    };
};
